//import axios from "axios"
const GROBAL = {

  BASE_SERVER: {
    // URL: 'http://183.88.214.11:6208/',
    // URL: 'http://127.0.0.1:6208/',
    URL: 'https://api.d-fix.thaiakitech.co.th/',
  },

  // -----------------------------------------------------------------------------------


  ACCESS_TOKEN: {
    'x-access-token': localStorage.getItem("x-access-token"),
  },
  PERMISSION: {
    'permission': localStorage.getItem("permission"),
    'temp_permission': localStorage.getItem("temp_permission")
  }
}

export default GROBAL