import { BaseFetch } from "../main-model"
export default class UserModel extends BaseFetch {
    getUserTableList = (data) =>
    this.authFetch({
      url: "api/User/getEmployeeTableList",
      method: "POST",
      body: data,
    })

    getUserTableById = (data) =>
    this.authFetch({
      url: "api/User/getUserTableById",
      method: "POST",
      body: data,
    })

    createUserTable = (data) =>
    this.authFetch({
      url: "api/User/createUserTable",
      method: "POST",
      body: data,
    })

    editUserTable = (data) =>
    this.authFetch({
      url: "api/User/editUserTable",
      method: "POST",
      body: data,
    })
    
    deleteUserTable = (data) =>
    this.authFetch({
      url: "api/User/deleteUserTable",
      method: "POST",
      body: data,
    })

    UpdateSettings = (data) =>
    this.authFetch({
      url: "api/User/UpdateSettings",
      method: "POST",
      body: data,
    })

    checkLogin = (data) =>
    this.authFetch({
      url: "user/checkLogin",
      method: "POST",
      body: data,
    })
}
