import { BaseFetch } from "../main-model";
export default class OilCarModel extends BaseFetch {
  getMileBy = (data) => {
    return this.directFetch({
      url: "oil-car/getMileBy",
      method: "POST",
      body: data,
    });
  };

  getCreditOilBy = (data) => {
    return this.directFetch({
      url: "oil-car/getCreditOilBy",
      method: "POST",
      body: data,
    });
  };

  getDisposableMonthBy = (data) => {
    return this.directFetch({
      url: "oil-car/getDisposableMonthBy",
      method: "POST",
      body: data,
    });
  };

  insertCreditOil = (data) => {
    return this.directFetch({
      url: "oil-car/insertCreditOil",
      method: "POST",
      body: data,
    });
  };

  updateCreditOil = (data) => {
    return this.directFetch({
      url: "oil-car/updateCreditOil",
      method: "POST",
      body: data,
    });
  };

  deleteCreditOilById = (data) => {
    return this.directFetch({
      url: "oil-car/deleteCreditOilById",
      method: "POST",
      body: data,
    });
  };

  getCreditOilByOrder = (data) => {
    return this.directFetch({
      url: "oil-car/getCreditOilByOrder",
      method: "POST",
      body: data,
    });
  };

  insertCreditOilCard = (data) => {
    return this.directFetch({
      url: "oil-car/insertCreditOilCard",
      method: "POST",
      body: data,
    });
  };

  insertCreditOilExtraOil = (data) => {
    return this.directFetch({
      url: "oil-car/insertCreditOilExtraOil",
      method: "POST",
      body: data,
    });
  };

  updateCreditOilRefuel = (data) => {
    return this.directFetch({
      url: "oil-car/updateCreditOilRefuel",
      method: "POST",
      body: data,
    });
  };

  getExportOilBy = (data) => {
    return this.directFetch({
      url: "oil-car/getExportOilBy",
      method: "POST",
      body: data,
    });
  };

  getCreditOilMonthly = (data) => {
    return this.directFetch({
      url: "oil-car/getCreditOilMonthly",
      method: "POST",
      body: data,
    });
  };
}
