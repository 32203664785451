import { BaseFetch } from "../main-model";
export default class ContactModel extends BaseFetch {
  getContactBy = (data) =>
    this.authFetch({
      url: "contact/getContactBy",
      method: "POST",
      body: data,
    });
  getContactById = (data) =>
    this.authFetch({
      url: "contact/getContactById",
      method: "POST",
      body: data,
    });
  insertContact = (data) =>
    this.authFetch({
      url: "contact/insertContact",
      method: "POST",
      body: data,
    });
  updateContactById = (data) =>
    this.authFetch({
      url: "contact/updateContactById",
      method: "POST",
      body: data,
    });
  deleteContactById = (data) =>
    this.authFetch({
      url: "contact/deleteContactById",
      method: "POST",
      body: data,
    });
  getContactByRefId = (data) =>
    this.authFetch({
      url: "contact/getContactByRefId",
      method: "POST",
      body: data,
    });
}
