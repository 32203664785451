import { BaseFetch } from "../main-model";
export default class ProvinceModel extends BaseFetch {

    getProvincesBy = (data) => {
      return this.directFetch({
        url: "provinces/getProvincesBy",
        method: "POST",
        body: data,
      })
    }

}
