import { BaseFetch } from "../main-model";
export default class WorkTypeModel extends BaseFetch {
  getWorkTypeBy = (data) =>
    this.authFetch({
      url: "work-type/getWorkTypeBy",
      method: "POST",
      body: data,
    });
  getWorkTypeById = (data) =>
    this.authFetch({
      url: "work-type/getWorkTypeById",
      method: "POST",
      body: data,
    });
  insertWorkType = (data) =>
    this.authFetch({
      url: "work-type/insertWorkType",
      method: "POST",
      body: data,
    });
  updateWorkTypeById = (data) =>
    this.authFetch({
      url: "work-type/updateWorkTypeById",
      method: "POST",
      body: data,
    });
  deleteWorkTypeById = (data) =>
    this.authFetch({
      url: "work-type/deleteWorkTypeById",
      method: "POST",
      body: data,
    });
}
