import React, { Component } from "react";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { AuthProvider } from "../../role-access/authContext";
import Authoring from "./Authoring";
import { AuthenModel, UserModel } from "../../models";
import { token } from "../../config/access-token";
const authen_model = new AuthenModel();
const user_model = new UserModel();

function Auth(props) {
  const [state, setState] = React.useState({
    authcertifying: true,
    authenticated: false,
    permissions: [],
    user: {},
  });
  // React.useEffect(() => {
  //   const unlisten = history.listen((location) => {
  //     console.log('Current Path:', location.pathname);
  //   });

  //   return () => {
  //     unlisten();
  //   };
  // }, [history]);

  React.useEffect(() => {
    initiateAuthentication();
  }, []);

  const checkLogin = async ({ username, password }) => {
    try {
      const res_login = await user_model.checkLogin({ username, password });
      if (!res_login || res_login.data.length === 0) {
        setState(prevState => ({
          ...prevState,
          authcertifying: false,
        }));
        Swal.fire({
          title: "ไม่สามารถล็อคอินได้ !",
          text: "โปรดตรวจสอบชื่อผู้ใช้และรหัสผ่านของคุณ",
          icon: "warning",
        });
      } else {
        localStorage.setItem("x-access-token", res_login.data.access_token);
        localStorage.setItem("session-user", JSON.stringify(res_login.data[0]));
        localStorage.setItem("p", password);
        localStorage.setItem("company_table_uuid", res_login.company_table_uuid);
        const { permissions } = jwt_decode(res_login.permissions_token)
        token["x-access-token"] = res_login.data.access_token;

        setState(prevState => ({
          ...prevState,
          authcertifying: false,
          authenticated: true,
          permissions: permissions || [],
          user: res_login.data[0],
        }));
      }
    } catch (error) {
      console.log("_checkLogin Error: ", error);
      setState(prevState => ({
        ...prevState,
        authcertifying: false,
      }));
      Swal.fire({
        title: "ไม่สามารถล็อคอินได้ !",
        text: "เกิดข้อผิดพลาดในการเข้าสู่ระบบ",
        icon: "error",
      });
    }
  };

  const initiateAuthentication = () => {
    try {
      const serialized = localStorage.getItem("session-user")
      const password = localStorage.getItem("p")
      if (serialized) {
        const { username } = JSON.parse(serialized)
        checkLogin({ username, password })
      } else {
        setState(prevState => ({
          ...prevState,
          authcertifying: false,
        }));
      }
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        authcertifying: false,
      }));
      localStorage.clear();
      console.log("_initiateAuthentication ", e);
    }
  }

  const handleLogin = (data) => {
    if (!state.authcertifying) {
      setState(prevState => ({
        ...prevState,
        authcertifying: true,
      }));
      checkLogin(data);
    }
  }

  const handleLogout = () => {
    try {
      localStorage.clear();
      window.location.reload();
    } catch (e) {
      console.log("_handleLogout ", e);
    }
  }

  return (
    <AuthProvider
      value={{
        ...state,
        _handleLogin: handleLogin,
        _handleLogout: handleLogout,
        _initiateAuthentication: initiateAuthentication,
      }}
    >
      {!state.authcertifying && props.children}
    </AuthProvider>
  );
}

export default Auth;
