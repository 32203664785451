import { BaseFetch } from "../main-model";
export default class LocationModel extends BaseFetch {

    getLocationBy = (data) =>
    this.authFetch({
      url: "location/getLocationBy",
      method: "POST",
      body: data,
    });
    getLocationById = (data) =>
    this.authFetch({
      url: "location/getLocationById",
      method: "POST",
      body: data,
    });
    insertLocation = (data) =>
    this.authFetch({
      url: "location/insertLocation",
      method: "POST",
      body: data,
    });
    updateLocationById = (data) =>
    this.authFetch({
      url: "location/updateLocationById",
      method: "POST",
      body: data,
    });
    deleteLocationById = (data) =>
    this.authFetch({
      url: "location/deleteLocationById",
      method: "POST",
      body: data,
    });
}
