import { BaseFetch } from "../main-model";
export default class GroupSpareModel extends BaseFetch {
  
    getGroupSpareBorrowBy = (data) =>
    this.authFetch({
      url: "group-spare/getGroupSpareBorrowBy",
      method: "POST",
      body: data,
    });
    getGroupSpareBy = (data) =>
      this.authFetch({
        url: "group-spare/getGroupSpareBy",
        method: "POST",
        body: data,
      });
    getGroupSpareById = (data) =>
    this.authFetch({
      url: "group-spare/getGroupSpareById",
      method: "POST",
      body: data,
    });
    getOnceSpareById = (data) =>
      this.authFetch({
        url: "group-spare/getOnceSpareById",
        method: "POST",
        body: data,
      });
    insertGroupSpare = (data) =>
    this.authFetch({
      url: "group-spare/insertGroupSpare",
      method: "POST",
      body: data,
    });
    insertSpare = (data) =>
      this.authFetch({
        url: "group-spare/insertSpare",
        method: "POST",
        body: data,
      });
    updateGroupSpareById = (data) =>
    this.authFetch({
      url: "group-spare/updateGroupSpareById",
      method: "POST",
      body: data,
    });
    updateGroupSpareBorrowById = (data) =>
      this.authFetch({
        url: "group-spare/updateGroupSpareBorrowById",
        method: "POST",
        body: data,
      });
    deleteGroupSpareById = (data) =>
    this.authFetch({
      url: "group-spare/deleteGroupSpareById",
      method: "POST",
      body: data,
    });
    deleteSpareById = (data) =>
      this.authFetch({
        url: "group-spare/deleteSpareById",
        method: "POST",
        body: data,
      });
}
