import { BaseFetch } from "../main-model";
export default class DailyemployeeModel extends BaseFetch {

    getDailyemployeeBy = (data) =>
    this.authFetch({
      url: "daily-employee/getDailyemployeeBy",
      method: "POST",
      body: data,
    });
    getDailyemployeeById = (data) =>
    this.authFetch({
      url: "daily-employee/getDailyemployeeById",
      method: "POST",
      body: data,
    });
    insertDailyemployee = (data) =>
    this.authFetch({
      url: "daily-employee/insertDailyemployee",
      method: "POST",
      body: data,
    });
    updateDailyemployeeById = (data) =>
    this.authFetch({
      url: "daily-employee/updateDailyemployeeById",
      method: "POST",
      body: data,
    });
    deleteDailyemployeeById = (data) =>
    this.authFetch({
      url: "daily-employee/deleteDailyemployeeById",
      method: "POST",
      body: data,
    });
}
