import { BaseFetch } from "../main-model";
export default class SubDistrictModel extends BaseFetch {
  getSubDistrictsBy = (data) => {
    return this.directFetch({
      url: "sub-districts/getSubDistrictsBy",
      method: "POST",
      body: data,
    })
  }
}
