import { BaseFetch } from "../main-model";
export default class SpareModel extends BaseFetch {
  getSpareAllBy = (data) =>
    this.authFetch({
      url: "spare/getSpareAllBy",
      method: "POST",
      body: data,
    });
    getSpareAllById = (data) =>
    this.authFetch({
      url: "spare/getSpareAllById",
      method: "POST",
      body: data,
    });
  getSpareBy = (data) =>
    this.authFetch({
      url: "spare/getSpareBy",
      method: "POST",
      body: data,
    });
  getSpareDisBy = (data) =>
    this.authFetch({
      url: "spare/getSpareDisBy",
      method: "POST",
      body: data,
    });
  getSpareById = (data) =>
    this.authFetch({
      url: "spare/getSpareById",
      method: "POST",
      body: data,
    });
    getSpareDisById = (data) =>
    this.authFetch({
      url: "spare/getSpareDisById",
      method: "POST",
      body: data,
    });
  insertSpare = (data) =>
    this.authFetch({
      url: "spare/insertSpare",
      method: "POST",
      body: data,
    });
    updateSpareCountById = (data) =>
    this.authFetch({
      url: "spare/updateSpareCountById",
      method: "POST",
      body: data,
    });
  updateSpareById = (data) =>
    this.authFetch({
      url: "spare/updateSpareById",
      method: "POST",
      body: data,
    });
  deleteSpareById = (data) =>
    this.authFetch({
      url: "spare/deleteSpareById",
      method: "POST",
      body: data,
    });
  insertSpareDis = (data) =>
    this.authFetch({
      url: "spare/insertSpareDis",
      method: "POST",
      body: data,
    });
  updateSpareDisById = (data) =>
    this.authFetch({
      url: "spare/updateSpareDisById",
      method: "POST",
      body: data,
    });
  deleteSpareDisById = (data) =>
    this.authFetch({
      url: "spare/deleteSpareDisById",
      method: "POST",
      body: data,
    });
}
