import { BaseFetch } from "../main-model"
export default class PermissionModel extends BaseFetch {
    getPermissionBy = (data) =>
    this.authFetch({
      url: "permission/getPermissionBy",
      method: "POST",
      body: data,
    })
    getPermissionByid = (data) =>
    this.authFetch({
      url: "permission/getPermissionByid",
      method: "POST",
      body: data,
    })
    updatePermissionById = (data) =>
    this.authFetch({
      url: "permission/updatePermissionById",
      method: "POST",
      body: data,
    })
    insertPermission = (data) =>
    this.authFetch({
      url: "permission/insertPermission",
      method: "POST",
      body: data,
    })
    deletePermissionById = (data) =>
    this.authFetch({
      url: "permission/deletePermissionById",
      method: "POST",
      body: data,
    })

    getMenuBy = (data) =>
    this.authFetch({
      url: "permission/getMenuBy",
      method: "POST",
      body: data,
    })
}
