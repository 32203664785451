import { BaseFetch } from "../main-model";
export default class OtModel extends BaseFetch {
  getOtBy = (data) =>
    this.authFetch({
      url: "ot/getOtBy",
      method: "POST",
      body: data,
    });
  getOtByCalculate = (data) =>
    this.authFetch({
      url: "ot/getOtByCalculate ",
      method: "POST",
      body: data,
    });
  getOtById = (data) =>
    this.authFetch({
      url: "ot/getOtById",
      method: "POST",
      body: data,
    });
  insertOt = (data) =>
    this.authFetch({
      url: "ot/insertOt",
      method: "POST",
      body: data,
    });
  updateOtById = (data) =>
    this.authFetch({
      url: "ot/updateOtById",
      method: "POST",
      body: data,
    });
  deleteOtById = (data) =>
    this.authFetch({
      url: "ot/deleteOtById",
      method: "POST",
      body: data,
    });
  getOtByStatus = (data) =>
    this.authFetch({
      url: "ot/getOtByStatus",
      method: "POST",
      body: data,
    });
  updateOtStatusById = (data) =>
    this.authFetch({
      url: "ot/updateOtStatusById",
      method: "POST",
      body: data,
    });
}
