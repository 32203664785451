import { BaseFetch } from "../main-model";
export default class StatusRepairModel extends BaseFetch {

    getTokenLineBy = (data) =>
    this.authFetch({
      url: "token-line/getTokenLineBy",
      method: "POST",
      body: data,
    });
    getTokenLineById = (data) =>
    this.authFetch({
      url: "token-line/getTokenLineById",
      method: "POST",
      body: data,
    });
    insertTokenLine = (data) =>
    this.authFetch({
      url: "token-line/insertTokenLine",
      method: "POST",
      body: data,
    });
    updateTokenLineById = (data) =>
    this.authFetch({
      url: "token-line/updateTokenLineById",
      method: "POST",
      body: data,
    });
    deleteTokenLineById = (data) =>
    this.authFetch({
      url: "token-line/deleteTokenLineById",
      method: "POST",
      body: data,
    });
}
