import { BaseFetch } from "../main-model";
export default class CompanyModel extends BaseFetch {

    getCompanyBy = (data) =>
    this.authFetch({
      url: "company/getCompanyBy",
      method: "POST",
      body: data,
    });
    getCompanyById = (data) =>
    this.authFetch({
      url: "company/getCompanyById",
      method: "POST",
      body: data,
    });
    insertCompany = (data) =>
    this.authFetch({
      url: "company/insertCompany",
      method: "POST",
      body: data,
    });
    updateCompanyById = (data) =>
    this.authFetch({
      url: "company/updateCompanyById",
      method: "POST",
      body: data,
    });
    deleteCompanyById = (data) =>
    this.authFetch({
      url: "company/deleteCompanyById",
      method: "POST",
      body: data,
    });
}
