import { BaseFetch } from "../main-model";
export default class StatusRepairModel extends BaseFetch {

    getStatusRepairBy = (data) =>
    this.authFetch({
      url: "status-repair/getStatusRepairBy",
      method: "POST",
      body: data,
    });
    getStatusRepairById = (data) =>
    this.authFetch({
      url: "status-repair/getStatusRepairById",
      method: "POST",
      body: data,
    });
    inserStatusRepair = (data) =>
    this.authFetch({
      url: "status-repair/inserStatusRepair",
      method: "POST",
      body: data,
    });
    updateStatusRepairById = (data) =>
    this.authFetch({
      url: "status-repair/updateStatusRepairById",
      method: "POST",
      body: data,
    });
    deleteStatusRepairById = (data) =>
    this.authFetch({
      url: "status-repair/deleteStatusRepairById",
      method: "POST",
      body: data,
    });
    getStatusRepairByDate = (data) =>
      this.authFetch({
        url: "status-repair/getStatusRepairByDate",
        method: "POST",
        body: data,
      });
}
